import '../../scss/core/app.scss';

const $ = require('jquery');
global.$ = global.jQuery = $;

import 'bootstrap';
import * as moment from 'moment';
import 'moment/min/locales';
window.moment = moment;
import * as daterangepicker from 'daterangepicker';
window.daterangepicker = daterangepicker;

/*global window */
(function (window, $, document) {
    let initialize = function (context) {
    };
    $(document).on('dcode_load', function (event) {
        initialize(event.currentTarget);
    });

    $(function () {
        initialize(document);
    });
})(window, window.jQuery, window.document);
